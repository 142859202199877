import React from 'react'
import Formulario from './form'
import Breadcrumbs from "../breadcrumbs";
import { graphql, useStaticQuery } from 'gatsby';

import {getBrText} from '../../helpers/helper.rendering'
import LateralContact from '../lateralContact';

const Main = () => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              global_email
              global_phone
              global_broker_id
              social{
                facebook
                instagram
                twitter
                youtube
                linkedin
              }
              keys{
                captcha
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                    services{
                        title
                        subtitle
                        paragraph
                        form
                    }
                    footer {
                        title_contact
                        title_social
                        title_newsletter
                        title_office
                        button_contact {
                            link
                            value
                        }
                    }
              }
          }
    }`)
    return realEstate.sections &&
      realEstate.sections.services.form ? (
        <section className="tasaciones-main">
            <div className="container-fluid">
                <div className="row mt-lg-0 center align-items-center">
                    <div className="col-12">
                        <div className="row content-main align-items-start">
                            <div className="col-12 mt-2 mt-lg-4">
                               <Breadcrumbs
                                   props={[
                                   { name: realEstate.name, route: "/", location: "" },
                                   { name: "Servicios", route:   "", location: ""},
                                   ]}
                               />
                           </div>
                            <div className="col-lg-8">
                                <h1 className="mb-lg-4 mb-2">{realEstate.sections.services.title}</h1>
                                {/* <h3 className="mb-4 mt-5 mt-lg-0">{realEstate.sections.services.subtitle}</h3> */}
                                <h2>
                                    {getBrText(realEstate.sections.services.paragraph).map((element) => (
                                        <>
                                            {element}
                                            <br />
                                        </>
                                    ))}
                                </h2>
                                <Formulario />
                            </div>
                            <div className="lateral-contact col-lg-3 offset-lg-1 text-right contact-nav d-none d-lg-flex align-items-start">
                                <LateralContact/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ):''
}

export default Main